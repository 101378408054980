<template>
  <div class="dynamic"   @click="liClick($event)">
    <vue-seamless-scroll :data="newsList" :class-option="defaultOption">
      <ul>
        <li
          v-for="(item, index) in newsList"
          :key="index"
          :data-id="item.procurementId"
        >
          <span class="truncate icon"></span>
          <span class="title" :data-id="item.procurementId">{{
            item.title
          }}</span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { selectProcurementNotice } from "@/api/CollectivePurchase";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {
      newsList: [],
    };
  },

  watch: {
    topSelectAreacode: function () {
      this.getDynamic();
      // if (
      //   this.topSelectAreacode.length > 4 &&
      //   this.topSelectAreacode !== undefined
      // ) {

      // } else {
      //   this.$message.info("请选择所属区县，查看对应产品");
      // }
    },
  },

  created() {
    this.getDynamic();
  },
  computed: {
    ...mapGetters(["topSelectAreacode", "identityObj"]),
    defaultOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: this.newsList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        limitMoveNum: this.newsList.length < 2 ? 2 : this.newsList.length,
      };
    },
  },
  components: {
    vueSeamlessScroll,
  },

  mounted() {
    this.getDynamic();
  },

  methods: {
    ...mapMutations(["SET_TABLElIST", "SET_FORMLIST", "SET_DISPOSITION"]),
    async getDynamic() {
      let params = {
        current: 1,
        size: 24,
        areaCode: this.topSelectAreacode,
      };
      let res = await selectProcurementNotice(params);
      if (!res || res.code != 0) {
        return;
      }
      this.newsList = res.data.records;
      console.log(this.newsList, " this.newsList ");
    },
    liClick($event) {
      // console.log($event.target.dataset, "查看id");
      // console.log($event.target.dataset.id, "查看$event");
      this.$router.push({
        name: "Preview",
        query: { data: $event.target.dataset.id },
      });
      // if (
      //   this.identityObj.identityTypeCode == "8" ||
      //   this.identityObj.identityTypeCode == "2"
      // ) {
      //   this.$message.info(
      //     "农资集采活动仅支持集体经济组织和新型经营主体报名参加"
      //   );
      // } else {

      // }
      let form = {
        title: "", //标题
        abstracts: "", //摘要
        others: "", //其他说明
        // createTime:'',//开始时间，
        endTime: "", //结束时间，
        purchasingPeriodTime: "", //预计采购时间
        status: "", //状态
        files: [], //文件
        paymentRatio: "", //支付比例
        areaCode: "", //地址
      };
      this.SET_FORMLIST(form);
      let tableList = [{ agriculturalType: "", tableData: [] }];
      this.SET_TABLElIST(tableList);
    },
    // ClickBtn(item) {
    //   console.log("执行");
    //   if (
    //     this.identityObj.identityTypeCode == "8" ||
    //     this.identityObj.identityTypeCode == "2"
    //   ) {
    //     this.$message.info(
    //       "农资集采活动仅支持集体经济组织和新型经营主体报名参加"
    //     );
    //   } else {
    //     this.$router.push({
    //       name: "Preview",
    //       query: { data: item.procurementId },
    //     });
    //   }
    //   let form = {
    //     title: "", //标题
    //     abstracts: "", //摘要
    //     others: "", //其他说明
    //     // createTime:'',//开始时间，
    //     endTime: "", //结束时间，
    //     purchasingPeriodTime: "", //预计采购时间
    //     status: "", //状态
    //     files: [], //文件
    //     paymentRatio: "", //支付比例
    //     areaCode: "", //地址
    //   };
    //   this.SET_FORMLIST(form);
    //   let tableList = [{ agriculturalType: "", tableData: [] }];
    //   this.SET_TABLElIST(tableList);
    // },
  },
};
</script>

<style lang="less" scoped>
.dynamic {
  position: fixed;
  right: 20px;
  bottom: 200px;
  max-height: 200px;
  max-width: 340px;
  overflow: hidden;
  z-index: 199;
  background: rgba(248, 245, 245, 0.8);
  ul,
  li {
    margin: 0;
    padding: 0;

  }
  ul li {
    padding: 0px 10px;
    list-style: none;
    width: 100%;
    display: flex;
    .title {
      max-height: 40px;
      color: red;
      display: -webkit-box;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*隐藏后添加省略号*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .title {
      cursor: pointer;
    }
  }
  .sub {
    ul {
      margin: 0;
      padding: 0;
    }
  }
}

.truncate {
  height: 50px;
  vertical-align: module;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 0px 10px 40px;
  &.icon {
    background: url("../../../assets/icon-msg.png") no-repeat 5px 5px;
  }
}
</style>
